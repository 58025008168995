import useBlogPosts from "@/hooks/useBlogPosts";
import { BlogPostCard } from "@/ui/BlogPostCard";
import { Carousel } from "@/ui/Carousel";
import cn from "classnames";
import { Col, Container, Row } from "react-bootstrap";

import { Buttons } from "@/ui/Buttons";
import classes from "./styles.module.scss";

export default function ({
  className = "py-6",
  title = "Latest News",
  description = "<p>Discover our latest news & blogs.</p>",
  buttons = [{ label: "View all Blogs", url: "/blog", variant: "blue" }],
  prefix,
  EditableText,
  EditableHtml,
  theme = "light",
}) {
  const posts = useBlogPosts({ limit: 12 });

  return (
    <div className={cn(className, classes.BlogPostsCarousel)} data-bs-theme={theme}>
      <Container fluid className="pe-0">
        <Row>
          <Col xs={12} md={3} className="mb-4 mb-md-0 ps-md-5 text-center text-md-start">
            <EditableText path="title" tag="h2">
              {title}
            </EditableText>
            <EditableHtml path="description">
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </EditableHtml>
            <Buttons className="mt-md-5 justify-content-md-start" buttons={buttons} />
          </Col>
          <Col xs={12} md={9}>
            <Carousel
              autoPlay={true}
              elementKey={prefix}
              spaceBetween={1}
              slidesPerView={1.2}
              breakpoints={{
                480: { slidesPerView: 1.6 },
                768: { slidesPerView: 1.8 },
                992: { slidesPerView: 2.4 },
                1200: { slidesPerView: 3 },
                1400: { slidesPerView: 3 },
              }}
              items={posts.map((post, idx) => (
                <BlogPostCard className="bg-white px-3" key={idx} {...post} />
              ))}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
